.page-header {

    &__bar {

        transition: opacity 0.3s, background-color 0.3s, color 0.3s;

        // If the header is transparent, set background color to transparent (unless the bar has been fixed)
        .is-transparent &:not(.fixed) {
            background-color: transparent;
            pointer-events: none;

            a, button {
                pointer-events: auto;
            }
        }

        // When the menu is open, the bar should always be fixed and not have any transforms
        @screen max-l {

            transition: opacity 0.3s, background-color 0.3s, color 0.15s;

            .menu-opening &,
            .menu-open &,
            .menu-closing & {
                position: fixed !important;
                transform: none !important;
            }

            .menu-opening &,
            .menu-open & {
                color: theme('colors.black') !important;
            }

            .menu-closing & {
                transition: color 0.15s 0.15s;
            }
        }

    }

    &__border {
        transition: opacity 0.3s, background-color 0.3s;
        @screen max-l {
            .menu-opening &,
            .menu-open & {
                transition: opacity 0.1s, background-color 0.1s;
                background-color: theme('colors.black');
                opacity: 0.1;
            }
        }
    }

    &__nav {

        @screen max-l {

            &-bar {
                transition: opacity 0.3s, background-color 0.3s;
                .menu-opening &,
                .menu-open & {
                    opacity: 1;
                }
                .menu-closing & {
                    transition: opacity 0.3s 0.15s, background-color 0.3s;
                }
                .has-open-panel & {
                    background-color: theme('colors.beige.DEFAULT');
                }
            }

            &-item {
                transition: padding-top 0.3s theme('transitionTimingFunction.in-out-quad'), margin-bottom 0.3s theme('transitionTimingFunction.in-out-quad'), background-color 0.3s;
                &:not(:first-child).is-open {
                    padding-top: 10px;
                }
                &.is-open {
                    margin-bottom: 10px;
                    background-color: theme('colors.system.background-hover');
                }
            }
        }

        @screen l {
            &-backdrop {
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.3s;
                body.menu-panel-open & {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }

}
