:root {
    // Hotfix for Tailwind 3's transform utilities being buggy in Safari <=14
    // https://github.com/tailwindlabs/tailwindcss/discussions/8788
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
}

:root {
    // Thank god for https://codepen.io/sosuke/pen/Pjoqqp
    --image-color-filter-orange: invert(39%) sepia(95%) saturate(1768%) hue-rotate(1deg) brightness(103%) contrast(105%);
    --image-color-filter-dark-green: invert(28%) sepia(8%) saturate(1084%) hue-rotate(22deg) brightness(93%) contrast(81%);
    --image-color-filter-light-green: invert(83%) sepia(2%) saturate(4523%) hue-rotate(22deg) brightness(77%) contrast(88%);
}

:root,
.theme-beige {
    --color-foreground: theme('colors.black');
    --color-foreground-alternate: theme('colors.black');
    --color-background: theme('colors.beige.50');
    --color-background-highlight: theme('colors.light-green.200');
    --color-background-absolute: theme('colors.white');
    
    --color-background-card: theme('colors.beige.100');
    --color-background-card-hover: theme('colors.beige.200');
    --color-background-card-highlight: theme('colors.beige.200');
    --color-background-card-highlight-hover: theme('colors.beige.300');
    
    --color-divider: theme('colors.system.divider');
    --opacity-divider: 1;
    
    --illustration-base-color: theme('colors.dark-green.700');
    --image-color-filter: var(--image-color-filter-dark-green);

    --color-error: theme('colors.red');
}

.theme-black {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    --color-foreground: theme('colors.beige.50');
    --color-foreground-alternate: theme('colors.light-green.400');
    --color-background: theme('colors.black');
    --color-background-highlight: theme('colors.dark-green.600');
    --color-background-absolute: theme('colors.real-black');
    
    --color-background-card: theme('colors.dark-green.700');
    --color-background-card-hover: theme('colors.dark-green.600');
    --color-background-card-highlight: theme('colors.dark-green.600');
    --color-background-card-highlight-hover: theme('colors.dark-green.500');
    
    --color-divider: theme('colors.light-green.900');
    --opacity-divider: 0.5;
    
    --illustration-base-color: theme('colors.beige.50');
    --image-color-filter: var(--image-color-filter-orange);
}

.theme-dark-green {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    --color-foreground: theme('colors.beige.50');
    --color-foreground-alternate: theme('colors.beige.50');
    --color-background: theme('colors.dark-green.700');
    --color-background-highlight: theme('colors.dark-green.500');
    --color-background-absolute: theme('colors.black');
    
    --color-background-card: theme('colors.dark-green.600');
    --color-background-card-hover: theme('colors.dark-green.500');
    --color-background-card-highlight: theme('colors.dark-green.500');
    --color-background-card-highlight-hover: theme('colors.dark-green.400');
    
    --color-divider: theme('colors.light-green.900');
    --opacity-divider: 0.5;

    --illustration-base-color: theme('colors.light-green.400');
    --image-color-filter: var(--image-color-filter-light-green);
}

.illustration-theme-light-green {
    --image-color-filter: var(--image-color-filter-light-green);
}

:root,
.ui-theme-black {
    --ui-color-btn-text-disabled: #878787;
    --ui-color-btn-background-disabled: #C4C4C4;
    --ui-color-btn-border-disabled: #C4C4C4;

    --ui-color-btn-primary-text: theme('colors.beige.50');
    --ui-color-btn-primary-background: theme('colors.black');
    --ui-color-btn-primary-border: theme('colors.black');
    --ui-color-btn-primary-text-hover: currentColor;
    --ui-color-btn-primary-background-hover: transparent;
    --ui-color-btn-primary-border-hover: theme('colors.black');
    --ui-color-btn-primary-text-active: theme('colors.beige.50');
    --ui-color-btn-primary-background-active: #424242;
    --ui-color-btn-primary-border-active: #424242;
    
    --ui-color-btn-secondary-text: currentColor;
    --ui-color-btn-secondary-background: transparent;
    --ui-color-btn-secondary-border: theme('colors.black');
    --ui-color-btn-secondary-text-hover: theme('colors.beige.50');
    --ui-color-btn-secondary-background-hover: #424242;
    --ui-color-btn-secondary-border-hover: #424242;
    --ui-color-btn-secondary-text-active: theme('colors.beige.50');
    --ui-color-btn-secondary-background-active: #4A4A4A;
    --ui-color-btn-secondary-border-active: #4A4A4A;
    
    --ui-color-btn-tertiary-text: currentColor;
    --ui-color-btn-tertiary-background: transparent;
    --ui-color-btn-tertiary-border: theme('colors.black');
    --ui-color-btn-tertiary-text-hover: theme('colors.beige.50');
    --ui-color-btn-tertiary-background-hover: theme('colors.black');
    --ui-color-btn-tertiary-border-hover: theme('colors.black');
    --ui-color-btn-tertiary-text-active: theme('colors.beige.50');
    --ui-color-btn-tertiary-background-active: #4A4A4A;
    --ui-color-btn-tertiary-border-active: theme('colors.black');
}

.ui-theme-orange {
    --ui-color-btn-text-disabled: #878787;
    --ui-color-btn-background-disabled: #C4C4C4;
    --ui-color-btn-border-disabled: #C4C4C4;

    --ui-color-btn-primary-text: theme('colors.orange.950');
    --ui-color-btn-primary-background: theme('colors.orange.500');
    --ui-color-btn-primary-border: theme('colors.orange.500');
    --ui-color-btn-primary-text-hover: currentColor;
    --ui-color-btn-primary-background-hover: transparent;
    --ui-color-btn-primary-border-hover: theme('colors.orange.500');
    --ui-color-btn-primary-text-active: currentColor;
    --ui-color-btn-primary-background-active: theme('colors.orange.400');
    --ui-color-btn-primary-border-active: theme('colors.orange.400');
    
    --ui-color-btn-secondary-text: currentColor;
    --ui-color-btn-secondary-background: transparent;
    --ui-color-btn-secondary-border: theme('colors.orange.500');
    --ui-color-btn-secondary-text-hover: theme('colors.orange.950');
    --ui-color-btn-secondary-background-hover: theme('colors.orange.400');
    --ui-color-btn-secondary-border-hover: theme('colors.orange.400');
    --ui-color-btn-secondary-text-active: theme('colors.orange.950');
    --ui-color-btn-secondary-background-active: theme('colors.orange.300');
    --ui-color-btn-secondary-border-active: theme('colors.orange.300');
    
    --ui-color-btn-tertiary-text: currentColor;
    --ui-color-btn-tertiary-background: transparent;
    --ui-color-btn-tertiary-border: theme('colors.orange.500');
    --ui-color-btn-tertiary-text-hover: theme('colors.orange.950');
    --ui-color-btn-tertiary-background-hover: theme('colors.orange.500');
    --ui-color-btn-tertiary-border-hover: theme('colors.orange.500');
    --ui-color-btn-tertiary-text-active: theme('colors.orange.950');
    --ui-color-btn-tertiary-background-active: theme('colors.orange.400');
    --ui-color-btn-tertiary-border-active: theme('colors.orange.500');
    
}

.ui-theme-green {
    --ui-color-btn-text-disabled: #878787;
    --ui-color-btn-background-disabled: #C4C4C4;
    --ui-color-btn-border-disabled: #C4C4C4;

    --ui-color-btn-primary-text: theme('colors.black');
    --ui-color-btn-primary-background: theme('colors.light-green.400');
    --ui-color-btn-primary-border: theme('colors.light-green.400');
    --ui-color-btn-primary-text-hover: currentColor;
    --ui-color-btn-primary-background-hover: transparent;
    --ui-color-btn-primary-border-hover: theme('colors.light-green.400');
    --ui-color-btn-primary-text-active: theme('colors.black');
    --ui-color-btn-primary-background-active: theme('colors.light-green.300');
    --ui-color-btn-primary-border-active: theme('colors.light-green.300');
    
    --ui-color-btn-secondary-text: currentColor;
    --ui-color-btn-secondary-background: transparent;
    --ui-color-btn-secondary-border: theme('colors.light-green.400');
    --ui-color-btn-secondary-text-hover: theme('colors.black');
    --ui-color-btn-secondary-background-hover: theme('colors.light-green.300');
    --ui-color-btn-secondary-border-hover: theme('colors.light-green.300');
    --ui-color-btn-secondary-text-active: theme('colors.black');
    --ui-color-btn-secondary-background-active: theme('colors.light-green.200');
    --ui-color-btn-secondary-border-active: theme('colors.light-green.200');
    
    --ui-color-btn-tertiary-text: currentColor;
    --ui-color-btn-tertiary-background: transparent;
    --ui-color-btn-tertiary-border: theme('colors.light-green.400');
    --ui-color-btn-tertiary-text-hover: theme('colors.black');
    --ui-color-btn-tertiary-background-hover: theme('colors.light-green.400');
    --ui-color-btn-tertiary-border-hover: theme('colors.light-green.400');
    --ui-color-btn-tertiary-text-active: theme('colors.black');
    --ui-color-btn-tertiary-background-active: theme('colors.light-green.300');
    --ui-color-btn-tertiary-border-active: theme('colors.light-green.400');
}

body.color-transitioning {
    transition: color var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad'), background-color var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad') !important;
    *[class*="duration"] {
        transition-property: background-color, border-color, opacity !important;
        transition-timing-function: theme('transitionTimingFunction.in-out-quad') !important;
    }
    .page-header__bar,
    .page-header__border,
    .h-px.bg-divider {
        transition: opacity var(--color-transition-duration, 0s) 0s, color var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad'), background-color var(--color-transition-duration, 0s) 0s theme('transitionTimingFunction.in-out-quad') !important;
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-300 ease-out-cubic;
}

html {
    font-feature-settings: "liga" 0, "dlig" 0;
}

html.no-js {
    img.lazyload {
        display: none;
    }
}

html.outline,
html.no-outline * {
    outline: none !important;
}

video[playsinline] {
    pointer-events: none;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

button {
    appearance: none;
    border-radius: 0;
    font-weight: normal;
    text-align: left;
}

[hidden] {
    display: none !important;
}

input:required {
    box-shadow: none !important;
}

select {
    appearance: none;
}

input[class*="appearance-none"] {
    -webkit-appearance: none;
}

// Prevent grid blowout on tiny screens
*:not([data-gridoverlay]) > .grid:not(.blowout) {
    // Max-width = (outerMargins * 2) + (gutter width * number of columns)
    @media (max-width: calc((theme('gutterMap.default') * theme('gridColumns')) + (theme('outerMarginsMap.default') * 2))) {
        grid-template-columns: minmax(0, 1fr);
    }
}

.hover-scale {
    @apply group-hover:scale-[1.015];
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

// Animate text-underline on hover
// But not for touch devices!
@media (hover: hover) and (pointer: fine) {
    [class*="hover:underline"] {
        text-underline-offset: 2px;
        @supports (transition: text-decoration-color) {
            text-decoration-line: underline;
            text-decoration-color: transparent;
            transition: text-decoration-color 0.3s;
            &:hover,
            .group:hover & {
                text-decoration-color: currentColor;
            }
        }
    }

    [class*="hover:no-underline"] {
        text-underline-offset: 2px;
        @supports (transition: text-decoration-color) {
            text-decoration-color: currentColor;
            transition: text-decoration-color 0.3s;
            &:hover,
            .group:hover & {
                text-decoration-line: underline !important;
                text-decoration-color: transparent;
            }
        }
    }
}
