@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}

    .display-1 {
        @apply text-80 leading-100 m:text-96 m:leading-104 l:text-150 l:leading-158;
    }
    .display-2 {
        @apply text-40 leading-48 m:text-60 m:leading-68 l:text-72 l:leading-80;
    }
    .h1 {
        @apply text-40 leading-48 m:text-48 m:leading-56 l:text-56 l:leading-64;
    }
    .h2 {
        @apply text-32 leading-40 m:text-40 m:leading-48 l:text-48 l:leading-56;
    }
    .h3 {
        @apply text-24 leading-32 m:text-32 m:leading-40 l:text-36 l:leading-44;
    }
    .paragraph {
        @apply text-20 leading-28 m:text-24 m:leading-32;
    }
    .large {
        @apply text-18 leading-24 m:text-20 m:leading-28;
    }
    .medium {
        @apply text-16 leading-24 m:text-18 m:leading-26;
    }
    .small {
        @apply text-14 leading-22 m:text-16 m:leading-24;
    }
    .detail-medium {
        @apply font-roobert-mono uppercase text-16 leading-24 tracking-[1.5px];
    }
    .detail-small {
        @apply font-roobert-mono uppercase text-12 leading-18 tracking-[0.5px] m:text-14 m:leading-20 m:tracking-[1.5px];
    }
    .caption {
        @apply text-12 leading-20;
    }
    .badge {
        @apply font-roobert-mono uppercase text-10 leading-18 m:text-12 m:leading-20 tracking-[0.5px];
    }
    
    
    .btn {
        @apply relative py-8 px-12 border-1 rounded-[4px] text-12 leading-1 font-roobert-mono tracking-[0.5px] uppercase whitespace-nowrap transition-colors duration-50;
        
        &--large {
            @apply text-14 py-12 px-16 tracking-[1.5px];
        }
        &--x-large {
            @apply text-16 py-20 px-24 tracking-[1.5px];
        }
        
        &[disabled] {
            color: var(--ui-color-btn-text-disabled) !important;
            background: var(--ui-color-btn-background-disabled) !important;;
            border-color: var(--ui-color-btn-border-disabled) !important;;
        }
        
        &:active {
            left: 1px;
            top: 1px;
        }
        
        &--primary {
            @apply rounded-full;
            
            color: var(--ui-color-btn-primary-text);
            background: var(--ui-color-btn-primary-background);
            border-color: var(--ui-color-btn-primary-border);
            
            &:hover {
                color: var(--ui-color-btn-primary-text-hover);
                background: var(--ui-color-btn-primary-background-hover);
                border-color: var(--ui-color-btn-primary-border-hover);
            }
            
            &:active {
                color: var(--ui-color-btn-primary-text-active);
                background: var(--ui-color-btn-primary-background-active);
                border-color: var(--ui-color-btn-primary-border-active);
            }
        }
        
        &--secondary {
            @apply rounded-full;
            
            color: var(--ui-color-btn-secondary-text);
            background: var(--ui-color-btn-secondary-background);
            border-color: var(--ui-color-btn-secondary-border);
            
            &:hover {
                color: var(--ui-color-btn-secondary-text-hover);
                background: var(--ui-color-btn-secondary-background-hover);
                border-color: var(--ui-color-btn-secondary-border-hover);
            }
            
            &:active {
                color: var(--ui-color-btn-secondary-text-active);
                background: var(--ui-color-btn-secondary-background-active);
                border-color: var(--ui-color-btn-secondary-border-active);
            }
        }
        
        &--tertiary {
            color: var(--ui-color-btn-tertiary-text);
            background: var(--ui-color-btn-tertiary-background);
            border-color: var(--ui-color-btn-tertiary-border);
            
            &:hover {
                color: var(--ui-color-btn-tertiary-text-hover);
                background: var(--ui-color-btn-tertiary-background-hover);
                border-color: var(--ui-color-btn-tertiary-border-hover);
            }
            
            &:active {
                color: var(--ui-color-btn-tertiary-text-active);
                background: var(--ui-color-btn-tertiary-background-active);
                border-color: var(--ui-color-btn-tertiary-border-active);
            }
        }
        
    }
}
