// Listing filters
.filters {
    &__search {
        &-input:not(:placeholder-shown) + &-label {
            opacity: 0 !important;
        }
        @screen max-l {
            &-input {
                width: 40px;
                color: transparent;
                &:focus {
                    width: calc(100vw - (var(--outer-margin) * 2));
                    color: currentColor;
                    padding-left: 16px;
                    padding-right: 40px;
                    @screen sp {
                        width: 240px;
                    }
                }
            }
            &-label {
                opacity: 0;
            }
            &-input:focus + &-label {
                opacity: 1;
            }
            &-input:focus + &-label,
            &-input:focus + &-label + &-bg {
                width: calc(100vw - (var(--outer-margin) * 2));
            }
            &-input:focus + &-label + &-bg + &-icon {
                right: 16px;
            }
        }
        @screen sp {
            &-input:focus + &-label,
            &-input:focus + &-label + &-bg {
                width: 100%;
            }
        }
        @screen l {
            &-input {
                width: 100%;
                padding-left: 16px;
                padding-right: 40px;
            }
        }
    }
}
