.wysiwyg {
    p, ul, ol, li {
        margin-bottom: calc((24/16) * 1em);
        
        @screen m {
            margin-bottom: calc((26/18) * 1em);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    a {
        text-decoration: underline;
        text-decoration-thickness: 0.05em;
        text-underline-offset: 0.15em;
        transition: text-decoration-color 0.1s;
        
        &:hover {
            text-decoration-color: transparent;
        }
    }
    
    h2 {
        @apply large mb-24 mt-48 first:mt-0;
        
        strong, em {
            font-weight: inherit;
            font-style: inherit;
        }
    }
    
    strong, b {
        @apply font-semibold;
    }
    
    em, i {
        @apply italic;
    }
    
    hr {
        display: none;
    }
    
    ul {

    }
    
    ol {
        counter-reset: item;
    }
    
    ol li {
        display: list-item;
    }
    
    li {
        position: relative;
        padding: 0 0 0 24px;
        
        @screen m {
            padding: 0 0 0 32px;
        }
        
    }
    
    ol li:before {
        font-variant-numeric: tabular-nums;
        position: absolute;
        content: counter(item) ".";
        counter-increment: item;
        left: 2px;
        top: 0;
        
        @screen m {
            left: 4px;
            top: 0;
        }
    }
    
    ul li:before {
        content: '●';
        font-family: sans-serif;
        position: absolute;
        left: 4px;
        top: 0;
        display: block;
        
        @screen m {
            left: 6px;
            top: 0;
        }
    }
    
    figure.table {
        width: 100%;
    }
    
    table {
        @apply border-1 border-divider;
        border-radius: 12px;
        overflow: hidden;
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }
    
    th, td {
        @apply border-divider;
        
        border-right-width: 1px;
        border-bottom-width: 1px;
        vertical-align: top;
        text-align: left;
        font-weight: normal;
        padding: 24px 16px;
        
        @screen l {
            padding: 40px 16px;
        }
        
        &:last-child {
            border-right-width: 0;
        }
    }
    
    tr:last-child {
        th, td {
            border-bottom-width: 0;
        }
    }
    
    th {
        @apply detail-medium bg-background-card;
    }
    
    td {

    }
    
    &--larger {
        h2 {
            @apply h3 mb-32 l:mb-80;
        }
    }
    
    &--banner-details {
        ul, ol {
            @apply medium;
            
            @screen l {
                margin-top: 48px;
                margin-bottom: 48px;
                column-count: 2;
                column-gap: 24px;
            }
        }
    }
}
